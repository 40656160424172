import Service01 from '../assets/images/services-01-courier.png';
import Service02 from '../assets/images/services-02-restau-food-shops.png';
import Service03 from '../assets/images/services-03-grocery.png';
import Service04 from '../assets/images/services-04-pharmacy.png';
import Service05 from '../assets/images/services-05-home-service.png';
import Service06 from '../assets/images/services-06-taxi.png';
import Service07 from '../assets/images/services-07-laundry.png';
import Service08 from '../assets/images/services-08-palengke.png';
import Service09 from '../assets/images/services-09-golf.png';
import Service10 from '../assets/images/services-10-emall.jpg';
import Service11 from '../assets/images/services-11-bills-payment.png';
import Service12 from '../assets/images/services-12-e-load.png';

import ImgNum1 from '../assets/images/num1.png';
import ImgNum2 from '../assets/images/num2.png';
import ImgNum3 from '../assets/images/num3.png';

import ImgBecomeAMerchant from '../assets/images/Be_a_Merchant.png';
import ImgBecomeARider from '../assets/images/Be_a_Rider.png';

import en from './localization/en';

const constants = {
  services: [
    {
      title: 'Courier',
      src: Service01
    },
    {
      title: 'Restaurants / Food Shops',
      src: Service02
    },
    {
      title: 'Grocery',
      src: Service03
    },
    {
      title: 'Pharmacy',
      src: Service04
    },
    {
      title: 'Home Service',
      src: Service05
    },
    {
      title: 'Taxi',
      src: Service06
    },
    {
      title: 'Laundry',
      src: Service07
    },
    {
      title: 'Palengke',
      src: Service08
    },
    {
      title: 'Golf',
      src: Service09
    },
    {
      title: 'e-Mall',
      src: Service10
    }
  ],

  steps: [
    {
      src: ImgNum1,
      title: 'Download the App',
      description: 'Click here to download the app'
    },
    {
      src: ImgNum2,
      title: 'Register',
      description: 'Fill up our form to become our partner'
    },
    {
      src: ImgNum3,
      title: 'Earn',
      description: 'Wait for your very first booking!'
    }
  ],

  invitation: [
    {
      title: en.becomeMerchant,
      img: ImgBecomeAMerchant,
      link: '/registration/merchant'
    },
    {
      title: en.becomeRider,
      img: ImgBecomeARider,
      link: '/registration/rider'
    }
  ],

  businesses: [
    {
      description:
        'Conveniently order at your preferred Restaurants, Drugstores, Grocery, Laundry, Hardware, Palengke, Pet Food, Flower Shops, and many more'
    },
    {
      description:
        'Courier at your service, same-day and scheduled collection and deliveries of documents, parcels, and cargoes'
    },
    {
      description: 'Ride-Hailing  (Taxi, Car, Tricycle, Habal-habal)'
    },
    {
      description:
        'Trained and vetted Cleaners, Carpenters, Electricians, Plumbers, Massage-therapists, etc. for Homes and Offices'
    },
    {
      description:
        'E-commerce marketplace, order online from a wide selection of specialty and essential items.'
    },
    {
      description:
        'Golf WHS Handicap, Accessories, Tee-times & Discounted Green Fees, Coaching and Tutorials'
    }
  ],
  faqs: [
    {
      title: en.genFaqs
    },
    {
      title: en.customer
    },
    {
      title: en.rider
    },
    {
      title: en.merchant
    }
  ]
};

export default constants;
