import { useRoutes } from 'react-router-dom';
import en from '../config/localization/en';
import Home from '../components/views/Home';
import { NotFound } from '../components/views/404NotFound';
import AboutUs from '../components/views/AboutUs';
import FAQs from '../components/views/FAQs';
import FAQsGeneral from '../components/views/FAQsGeneral';
import FAQsCustomer from '../components/views/FAQsCustomer';
import FAQsMerchant from '../components/views/FAQsMerchant';
import FAQsRider from '../components/views/FAQsRider';
import Registration from '../components/views/Registration';
import RegistrationMerchant from '../components/views/RegistrationMerchant';
import RegistrationRider from '../components/views/RegistrationRider';
import PrivacyPolicy from '../components/views/PrivacyPolicy';
import TermsAndConditions from '../components/views/TermsAndConditions';

import FAQsCustomerGeneral from '../components/views/FAQsCustomer/General';
import FAQsCustomerCourier from '../components/views/FAQsCustomer/Courier';
import FAQsCustomerFood from '../components/views/FAQsCustomer/Food';
import FAQsCustomerGrocery from '../components/views/FAQsCustomer/Grocery';
import FAQsCustomerPharmacy from '../components/views/FAQsCustomer/Pharmacy';
import FAQsCustomerHomeService from '../components/views/FAQsCustomer/HomeService';
import FAQsCustomerTaxi from '../components/views/FAQsCustomer/Taxi';
import FAQsCustomerLaundry from '../components/views/FAQsCustomer/Laundry';
import FAQsCustomerPalengke from '../components/views/FAQsCustomer/Palengke';

export const elements = [
  {
    path: '/',
    element: <Home />,
    name: en.home,
    match: '/',
    exact: true,
    mainNav: true
  },
  {
    path: '/aboutus',
    element: <AboutUs />,
    name: en.aboutUs,
    match: 'aboutus',
    exact: true,
    mainNav: true
  },
  // {
  //   path: '/privacy-policy',
  //   element: <PrivacyPolicy />,
  //   name: en.privacyPolicy,
  //   match: 'privacy-policy',
  //   exact: true,
  //   mainNav: false,
  // },
  // {
  //   path: '/terms-and-conditions',
  //   element: <TermsAndConditions />,
  //   name: en.termsAndConditions,
  //   match: 'terms-and-conditions',
  //   exact: true,
  //   mainNav: false
  // },
  {
    path: false,
    link: 'https://help.1app.ph/help/47467404',
    name: en.supportTicket,
    match: 'support-ticket',
    exact: true,
    mainNav: true
  },
  // {
  //   path: "/faqs",
  //   name: en.faqs,
  //   pathMatch: "full",
  //   match: "faqs",
  //   mainNav: true,
  //   children: [
  //     {
  //       path: "",
  //       pathMatch: "full",
  //       element: <FAQs />,
  //       match: "faqs",
  //     },
  //     {
  //       path: "/faqs/general",
  //       element: <FAQsGeneral />,
  //       name: en.genFaqs,
  //       match: "/faqs/general",
  //       exact: true,
  //     },
  //     {
  //       path: "/faqs/customer",
  //       element: <FAQsCustomer />,
  //       name: en.customer,
  //       match: "faqs/customer",
  //       exact: true,
  //       children: [
  //         {
  //           path: "/faqs/customer",
  //           element: <FAQsCustomerGeneral />,
  //           name: "General Customer FAQs",
  //           match: "/faqs/customer",
  //           exact: true,
  //         },
  //         {
  //           path: "/faqs/customer/courier",
  //           element: <FAQsCustomerCourier />,
  //           exact: true,
  //           name: en.courier,
  //           match: "/faqs/customer/courier",
  //         },
  //         {
  //           path: "/faqs/customer/food",
  //           element: <FAQsCustomerFood />,
  //           exact: true,
  //           name: en.restaurantsFoodShops,
  //           match: "/faqs/customer/food",
  //         },
  //         {
  //           path: "/faqs/customer/grocery",
  //           element: <FAQsCustomerGrocery />,
  //           exact: true,
  //           name: en.grocery,
  //           match: "/faqs/customer/grocery",
  //         },
  //         {
  //           path: "/faqs/customer/pharmacy",
  //           element: <FAQsCustomerPharmacy />,
  //           exact: true,
  //           name: en.pharmacy,
  //           match: "/faqs/customer/pharmacy",
  //         },
  //         {
  //           path: "/faqs/customer/homeservice",
  //           element: <FAQsCustomerHomeService />,
  //           exact: true,
  //           name: en.homeService,
  //           match: "/faqs/customer/homeservice",
  //         },
  //         {
  //           path: "/faqs/customer/taxi",
  //           element: <FAQsCustomerTaxi />,
  //           exact: true,
  //           name: en.taxi,
  //           match: "/faqs/customer/taxi",
  //         },
  //         {
  //           path: "/faqs/customer/laundry",
  //           element: <FAQsCustomerLaundry />,
  //           exact: true,
  //           name: en.laundry,
  //           match: "/faqs/customer/laundry",
  //         },
  //         {
  //           path: "/faqs/customer/palengke",
  //           element: <FAQsCustomerPalengke />,
  //           exact: true,
  //           name: en.palengke,
  //           match: "/faqs/customer/palengke",
  //         },
  //       ],
  //     },
  //     {
  //       path: "/faqs/merchant",
  //       element: <FAQsMerchant />,
  //       name: en.merchant,
  //       match: "/faqs/merchant",
  //       exact: true,
  //     },
  //     {
  //       path: "/faqs/rider",
  //       element: <FAQsRider />,
  //       name: en.rider,
  //       match: "/faqs/rider",
  //       exact: true,
  //     },
  //   ],
  //   exact: true,
  // },
  {
    path: '/registration',
    name: en.becomePartner,
    match: 'registration',
    mainNav: true,
    children: [
      {
        path: '',
        pathMatch: 'full',
        element: <Registration />
      },
      {
        path: 'merchant',
        element: <RegistrationMerchant />,
        name: en.merchant,
        match: 'registration/merchant',
        exact: true
      },
      {
        path: 'rider',
        element: <RegistrationRider />,
        name: en.rider,
        match: 'registration/rider',
        exact: true
      }
    ],
    exact: true
  },
  {
    path: '*',
    element: <NotFound />,
    name: en.notFound,
    match: '*',
    exact: true,
    mainNav: false
  }
];

const Router = () => {
  const routes = useRoutes(elements);

  return routes;
};

export default Router;
