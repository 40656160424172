import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <div>
      <CssBaseline />
      <Header />
      <Toolbar
        disableGutters
        sx={{ minHeight: { xs: "75px", md: "85px" } }}
        id="back-to-top-anchor"
      />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
