import { useState } from 'react';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Slider from '../Slider';
import { post } from 'axios';

import Loading from '../layout/Loading';
import Layout from '../layout';

import ImgMobileApp from '../../assets/images/mobile-app.png';
import ImgEarnMoney from '../../assets/images/earn-money.png';
import Img1appBldg from '../../assets/images/1app-bldg.jpg';
import ImgBackground from '../../assets/images/background.jpg';

import en from '../../config/localization/en';

import constants from '../../config/constants';

const { services, steps, invitation, businesses } = constants;

const Home = (props) => {
  return (
    <Layout>
      <Slider />
      <Box
        sx={(theme) => ({
          boxShadow: 2,
          width: 1,
          height: [
            '205px',
            '340px',
            '505px',
            '675px',
            `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`
          ]
        })}
      >
        <iframe
          src="https://www.youtube.com/embed/Kt3-BDR7lNQ?controls=0&autoplay=1&loop=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ width: '100%', height: '100%' }}
        ></iframe>
      </Box>
      <Container>
        <Box sx={{ py: 4 }}>
          <Typography
            variant="h4"
            color="primary"
            component="div"
            sx={{ textAlign: 'center', mb: 2 }}
          >
            1app Services
          </Typography>
          <Grid container spacing={2}>
            {services.map((item, i) => (
              <ServicesItem key={i} item={item} />
            ))}
          </Grid>
        </Box>
      </Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        sx={{ backgroundColor: '#dd2225', minHeight: '500px' }}
      >
        <Container>
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} md={3} lg={4}>
              <Box sx={{ width: [1, '300px'], padding: 1, mx: 'auto' }}>
                <img
                  src={ImgMobileApp}
                  alt="Mobile App"
                  style={{ width: '100%' }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={9} lg={8}>
              <Typography variant="p" component="div" color="white">
                <span className="p-title">
                  Advanced Technology – Visibility and Messaging
                </span>{' '}
                – Thanks to 1app’s cutting-edge technology, you can track your
                deliveries in real-time, from pick up to drop off, and can share
                this with your customers through a built-in messaging service.
              </Typography>
              <Typography
                variant="p"
                component="div"
                color="white"
                sx={{ my: 4 }}
              >
                <span className="p-title">Multi-Modal Delivery</span> – Our
                platform can help you manage all of your last-mile needs. From
                on-demand to scheduled deliveries, we work with restaurants,
                groceries, drug stores, laundry shops, e-commerce, and other
                online sellers. We connect you to a fleet of independent
                couriers, who will be able to complete your deliveries on the
                appropriate transport type for your package size, including
                bicycles, motorbikes, cars, vans, and trucks
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container>
        <Box>
          <Typography
            variant="h5"
            component="div"
            color="primary"
            sx={{ textAlign: 'center', py: 2 }}
          >
            {en.becomeOurPartner}
          </Typography>
          <Typography
            variant="subtitle"
            component="div"
            sx={{ textAlign: 'center' }}
          >
            Just follow these three easy steps
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ mt: 3 }}>
              {steps.map((item, i) => (
                <StepsItem key={i} item={item} />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                mt: 3,
                display: ['none', 'none', 'flex'],
                width: ['0', '0', '350px'],
                mx: 'auto'
              }}
            >
              <img
                src={ImgEarnMoney}
                alt="Earn Money"
                style={{ width: '100%' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            background: '#7f0102'
          },
          [theme.breakpoints.up('md')]: {
            background: `url(${ImgBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }
        })}
      >
        <Container sx={{ py: 5 }}>
          <Grid container rowGap={1}>
            {invitation.map((item, i) => (
              <InvitationItem key={i} item={item} />
            ))}
          </Grid>
        </Container>
      </Box>
      <Container sx={{ py: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" color="primary" component="div" mt={2}>
              {en.aboutApp}
            </Typography>
            <Typography variant="p" component="div" mt={2}>
              <strong>1app</strong> is an on-demand mobile and web application
              that connects businesses to a fleet of independent couriers in the
              Philippines. Our platform allows merchants of any industry to
              reach their customers with speed and efficiency.
            </Typography>
            <Typography variant="p" component="div" mt={2}>
              <strong>1app</strong> is an all-in-one app that can give you
              instant access to the following:
            </Typography>
            <ul>
              {businesses.map((item, i) => (
                <li key={i}>
                  <Typography variant="subtitle" component="div" mt={2}>
                    {item.description}
                  </Typography>
                </li>
              ))}
            </ul>
            <Typography variant="p" component="div" mt={2}>
              <strong>1app</strong> is owned and operated by Cloud Computing and
              Mobile Solutions, Inc., established in 2014. We are a technology
              company developing and maintaining business solutions in the
              energy, logistics, food, and farming industries.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="p" component="div" mt={2}>
              Our command center is located at {en.commandCenterAddress}
            </Typography>
            <Box xs={{ width: '100%', textAlign: 'center' }}>
              <img
                src={Img1appBldg}
                alt="1app Command Center"
                style={{ width: '100%' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ backgroundColor: '#dd2225' }}>
        <Container sx={{ color: 'white', py: 2 }}>
          <Typography variant="h4" component="div" my={2}>
            Get in touch!
          </Typography>
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" component="div">
                For more info, please visit our FAQs page or leave us a message!
              </Typography>
              <Typography variant="h6" component="div" mt={2}>
                <MailIcon sx={{ position: 'absolute', marginTop: '5px' }} />{' '}
                <span style={{ marginLeft: '30px' }}>wecare@1app.ph</span>
              </Typography>
              <Typography variant="h6" component="div" mt={2}>
                <LocationOnIcon
                  sx={{ position: 'absolute', marginTop: '5px' }}
                />{' '}
                <span style={{ marginLeft: '30px' }}>
                  1app Command Center, 4/F DellSons Bldg.., 129 13th Ave.,
                  Cubao, QC (near Ali Mall)
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <ContactUsForm />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

const ServicesItem = (props) => {
  const { item } = props;
  return (
    <Grid item xs={6} sm={6} md={4} lg={2.4}>
      <Card sx={{ width: 1, height: 1 }}>
        <CardMedia component="img" image={item.src} alt={item.title} />
      </Card>
    </Grid>
  );
};

// <CardContent sx={{ padding: "4px" }}>
//           <Typography
//             gutterBottom
//             variant="h6"
//             color="primary"
//             component="div"
//             sx={{ textAlign: "center" }}
//           >
//             {item.title}
//           </Typography>
//         </CardContent>

const StepsItem = (props) => {
  const { item } = props;
  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={3}>
        <Box sx={{ width: '80px' }}>
          <img src={item.src} alt={item.title} style={{ width: '100%' }} />
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Typography gutterBottom variant="h5" color="primary" component="div">
          {item.title}
        </Typography>
        <Typography gutterBottom variant="subtitle" component="div">
          {item.description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const InvitationItem = (props) => {
  const { item } = props;

  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={(theme) => ({
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
          '&:first-of-type': {
            borderBottom: '1px solid white'
          }
        },
        [theme.breakpoints.up('md')]: {
          '&:first-of-type': {
            borderRight: '1px solid white'
          }
        }
      })}
    >
      <Typography variant="h5" component="h5" color="white">
        {item.title}
      </Typography>
      <Box sx={{ width: '100%' }}>
        <img src={item.img} alt={item.title} style={{ width: '100%' }} />
      </Box>
      <Box sx={{ width: '100%', px: 2 }} mb={3}>
        <Button
          variant="contained"
          size="large"
          sx={{
            width: [1, 1, '50%'],
            borderRadius: 5
          }}
          component={Link}
          to={item.link}
        >
          {en.applyNow}
        </Button>
      </Box>
    </Grid>
  );
};

const initialFormData = Object.freeze({
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  message: '',
  'contact-type': 'Customer'
});

const ContactUsForm = () => {
  const [data, setData] = useState(initialFormData);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleOnChange = (e) => {
    e.preventDefault();
    setData({ ...data, ...{ [e.target.name]: e.target.value } });
  };

  const successAlert = () => {
    return (
      <Alert severity="success">
        <AlertTitle> Thank you for messaging us!</AlertTitle>
        We will get back to you as soon as possible
      </Alert>
    );
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    const newFormData = new FormData();
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    for (const key in data) {
      newFormData.append(key, data[key]);
    }
    newFormData.append('type', 'contactus');
    post('https://1app.ph/handler/handlerContactUs.php', newFormData, config)
      .then((response) => {
        const { data } = response;
        if (data.result === 'success') {
          setSuccess(true);
          setTimeout(() => setSuccess(false), 10000);
          setData(initialFormData);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        console.error(err);
      });
  };

  return (
    <Paper sx={{ px: 2, py: 4 }}>
      <Loading open={submitting} />
      {success ? successAlert() : null}
      <form onSubmit={handleOnSubmit}>
        <TextField
          id="inp-firstName"
          name="firstname"
          sx={{ width: 1, my: 2 }}
          label={en.firstName}
          variant="outlined"
          onChange={handleOnChange}
          value={data.firstname}
        />
        <TextField
          id="inp-lastName"
          name="lastname"
          sx={{ width: 1, my: 2 }}
          label={en.lastName}
          variant="outlined"
          onChange={handleOnChange}
          value={data.lastname}
        />
        <TextField
          id="inp-email"
          name="email"
          sx={{ width: 1, my: 2 }}
          label={en.emailAdd}
          variant="outlined"
          onChange={handleOnChange}
          value={data.email}
        />
        <TextField
          id="inp-phone"
          name="phone"
          sx={{ width: 1, my: 2 }}
          label={en.phone}
          variant="outlined"
          onChange={handleOnChange}
          value={data.phone}
        />
        <TextField
          id="inp-message"
          name="message"
          multiline
          label={en.message}
          variant="outlined"
          rows={4}
          sx={{ width: 1, my: 2 }}
          onChange={handleOnChange}
          value={data.message}
        />
        <TextField
          id="inp-contact-type"
          name="contact-type"
          label={en.contactType}
          variant="outlined"
          select
          sx={{ width: 1, my: 2 }}
          onChange={handleOnChange}
          value={data['contact-type']}
        >
          <MenuItem value={en.customer}>{en.customer}</MenuItem>
          <MenuItem value={en.businessOwner.replace(' ', '')}>
            {en.businessOwner}
          </MenuItem>
          <MenuItem value={en.rider}>{en.rider}</MenuItem>
        </TextField>

        <Button
          type="submit"
          variant="contained"
          size="medium"
          fullWidth
          disabled={submitting}
        >
          {submitting ? en.sending : en.submit}
        </Button>
      </form>
    </Paper>
  );
};

export default Home;
