const dimentsion = {
  inputHeight: "42px",
  borderRadius: "4px",
  sidebarWidth: "28%",
  sidebarWidthTablet: "52px",
  drawerWidthDesktop: "360px",
  drawerWidthTablet: "320px",
  columnWidthDate: 160,
  columnWidthNumber: 130,
  columnWidthString: 160,
  columnWidthBoolean: 130,
  bottomNavMaxWidth: "400px",
};

export default dimentsion;
