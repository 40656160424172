import Layout from "../layout";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import FAQsSideNav from "../layout/FAQsSideNav";

import constants from "../../config/constants";

import en from "../../config/localization/en";

const { businesses } = constants;

const FAQsGeneral = () => {
  return (
    <Layout>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <FAQsSideNav />
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography
              variant="h3"
              component="h5"
              color="primary"
              mt={2}
              sx={{ textAlign: "center" }}
            >
              {en.genFaqs}
            </Typography>
            <Typography variant="h5" component="h5" color="primary" mt={2}>
              {`Who is ${en.appName}?`}
            </Typography>
            <Typography variant="p" component="p" mt={2}>
              <strong>1app</strong> is a super app made for the Filipino People.
              It provides a Business ecosystem for the Philippine economies that
              connect Filipinos to millions of users, businesses, delivery
              service providers, and merchants.
            </Typography>
            <Typography variant="p" component="p" mt={2}>
              <strong>1app</strong> allows users to access an extensive array of
              services near their area and directly request that service online
              or via mobile with a single tap of a button. Services such as:
            </Typography>
            <ul>
              {businesses.map((item, i) => (
                <li key={i}>
                  <Typography variant="subtitle" component="div" mt={2}>
                    {item.description}
                  </Typography>
                </li>
              ))}
            </ul>
            <Typography variant="p" component="p" mt={2}>
              We aim to be at the forefront of innovative solutions to provide
              the best convenient service experience.
            </Typography>
            <Typography variant="p" component="p" mt={2}>
              <strong>1app</strong> partners with enterprises of all sizes to
              solve their delivery issues. From small-time foodies and hardware
              stores to restaurants, suppliers, and on-demand services.{" "}
              <strong>1app</strong> helps enterprises from various industries
              scale and outsource their deliveries according to their needs.
            </Typography>
            <Typography variant="p" component="p" mt={2}>
              We are a team of proud Pinoy problem-solvers, dedicating hours
              around the clock to make 1app the most reliable online tool for
              all convenience needs.
            </Typography>
            <Typography variant="h5" component="h5" color="primary" mt={2}>
              When will my order/package be delivered (Parcel)?
            </Typography>
            <ul>
              <li>
                Scheduled delivery – delivery on your preferred date and time.
              </li>
              <li>
                On-Demand delivery – once order is placed, you’ll be assigned to
                a rider for pick-up as soon as possible.
              </li>
            </ul>
            <Typography variant="h5" component="h5" color="primary" mt={2}>
              How many stops can 1app deliver (parcel) make in one booking?
            </Typography>
            <Typography variant="p" component="p" mt={2}>
              Multi-drop. You can add up to x stops.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default FAQsGeneral;
