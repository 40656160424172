import Layout from '../layout';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Img1appBldg from '../../assets/images/1app-bldg.jpg';

import en from '../../config/localization/en';
import constants from '../../config/constants';

const { businesses } = constants;

const AboutUs = () => {
  return (
    <Layout>
      <Container sx={{ py: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" color="primary" component="div" mt={2}>
              {en.aboutApp}
            </Typography>
            <Typography variant="p" component="div" mt={2}>
              <strong>1app</strong> is an on-demand mobile and web application
              that connects businesses to a fleet of independent couriers in the
              Philippines. Our platform allows merchants of any industry to
              reach their customers with speed and efficiency.
            </Typography>
            <Typography variant="p" component="div" mt={2}>
              <strong>1app</strong> is an all-in-one app that can give you
              instant access to the following:
            </Typography>
            <ul>
              {businesses.map((item, i) => (
                <li key={i}>
                  <Typography variant="subtitle" component="div" mt={2}>
                    {item.description}
                  </Typography>
                </li>
              ))}
            </ul>
            <Typography variant="p" component="div" mt={2}>
              <strong>1app</strong> is owned and operated by Cloud Computing and
              Mobile Solutions, Inc., established in 2014. We are a technology
              company developing and maintaining business solutions in the
              energy, logistics, food, and farming industries.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="p" component="div" mt={2}>
              Our command center is located at {en.commandCenterAddress}
            </Typography>
            <Box xs={{ width: '100%', textAlign: 'center' }}>
              <img
                src={Img1appBldg}
                alt="1app Command Center"
                style={{ width: '100%' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default AboutUs;
