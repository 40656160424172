const app = '1app';
const en = {
  appName: '1app',
  commandCenterAddress: `4/F DellSons Bldg.., 129 13th Ave., Cubao, QC (near Ali Mall)`,
  // nav
  home: 'Home',
  notFound: 'Page Not Found!',
  aboutUs: 'About us',
  faqs: 'FAQS',
  supportTicket: 'Support Ticket',
  contactUs: 'Contact us',
  becomePartner: 'Become a Partner',
  privacyPolicy: 'Privacy Policy',
  termsAndConditions: 'Terms and Conditions',
  // Services
  appServices: `${app} Services`,
  courier: 'Courier',
  restaurantsFoodShops: 'Restaurants / Food Shops',
  grocery: 'Grocery',
  pharmacy: 'Pharmacy',
  homeService: 'Home Service',
  taxi: 'Taxi',
  laundry: 'Laundry',
  palengke: 'Palengke',
  golf: 'Golf',
  // Page Text Contents
  advTech: 'Advanced Technology – Visibility and Messaging',
  advTechDes: `Thanks to ${app}  cutting-edge technology, you can track your deliveries in real-time, from pick up to drop off, and can share this with your customers through a built-in messaging service.`,
  mulModDel: 'Multi-Modal Delivery',
  mulModDelDes:
    'Our platform can help you manage all of your last-mile needs. From on-demand to scheduled deliveries, we work with restaurants, groceries, drug stores, laundry shops, e-commerce, and other online sellers. We connect you to a fleet of independent couriers, who will be able to complete your deliveries on the appropriate transport type for your package size, including bicycles, motorbikes, cars, vans, and trucks',
  becomeOurPartner: 'Become Our Partner Now!',
  followThreeSteps: 'Just follow these three easy steps',
  step1: 'Download the app',
  step1Des: 'Click here to download the app',
  step2: 'Register',
  step2Des: 'Fill up the form here to become our partner',
  step3: 'Earn',
  step3Des: 'Wait for your very first booking!',
  becomeMerchant: 'Be our Merchant Partner',
  becomeRider: 'Be our Delivery Partner',
  applyNow: 'Apply Now',
  aboutApp: `About ${app}`,
  email: 'wecare@1app.ph',
  // Forms
  contactType: 'Contact Type',
  driver: 'Driver',
  vendor: 'Vendor',
  firstName: 'First Name',
  lastName: 'Last Name',
  emailAdd: 'Email',
  phone: 'Phone',
  message: 'Message',
  partnerType: 'I am a ...',
  businessOwner: 'Business Owner',
  submit: 'Submit',
  sending: 'Sending ...',
  name: 'Name',
  password: 'Desired Password',
  referalCode: 'Referral Code',
  documents: 'Documents',
  driversLicense: 'Drivers License',
  nbiPol: 'NBI and Police Clearance',
  govId: 'Government Issued ID',
  covidVax: 'Covid Vaccination Card/ID',
  orCr: 'OR/CR',
  motorPhoto: 'Motor Photo',
  loa: 'Letter of Authorization',
  businessInfo: 'Business Information',
  businessName: 'Business Name',
  vendorType: 'Vendor Type',
  businessPermit: 'Business Permit',
  dtiSec: 'DTI/SEC Certificate',
  photoEstab: 'Photo of Establishment',
  businessEmail: 'Business Email',
  personalInfo: 'Personal Information',
  storeAddress: 'Business Address',
  storeStreet1: 'Street...',
  storeStreet2: 'Street 2...',
  storeCity: 'City',
  // FAQS
  genFaqs: 'General FAQs',
  customer: 'Customer',
  rider: 'Rider',
  merchant: 'Merchant'
};

export default en;
