import React, { useState } from 'react';

import { NavLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors';

import Sidebar from './Sidebar';

import Logo from '../../assets/images/1app_logo.png';

import { elements } from '../../router';

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const NavBar = (props) => {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  return (
    <ElevationScroll {...props}>
      <AppBar sx={{ bgcolor: grey[50] }}>
        <Container maxWidth="xl" sx={{ paddingLeft: 0 }}>
          <Toolbar
            disableGutters
            sx={{ minHeight: { xs: '75px', md: '85px' } }}
          >
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={onClick}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', sm: 'none' },
                width: { xs: '50px' },
              }}
            >
              <img src={Logo} alt="1app Logo" style={{ width: '60px' }} />
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mt: 1, display: { xs: 'none', sm: 'block' } }}
            >
              <img src={Logo} alt="1app Logo" style={{ width: '75px' }} />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {elements
                .filter((ele) => ele.mainNav === true)
                .map((page, i) => (
                  <div key={i}>
                    {page.path ? (
                      <Button
                        sx={{
                          my: 2,
                          color: 'primary',
                          display: 'block',
                          fontSize: '1rem',
                          fontWeight: 600,
                          px: 3,
                        }}
                        component={NavLink}
                        to={`${page.path}`}
                        style={({ isActive }) =>
                          isActive
                            ? {
                                borderBottom: '3px solid #f44336',
                                borderRadius: 0,
                              }
                            : null
                        }
                      >
                        {page.name}
                      </Button>
                    ) : (
                      <Button
                        sx={{
                          my: 2,
                          color: 'primary',
                          display: 'block',
                          fontSize: '1rem',
                          fontWeight: 600,
                          px: 3,
                        }}
                        href={`${page.link}`}
                        target="_blank"
                        rel="noreferrer"
                        style={null}
                      >
                        {page.name}
                      </Button>
                    )}
                  </div>
                ))}
            </Box>
          </Toolbar>
        </Container>
        <Sidebar open={open} setOpen={setOpen} />
      </AppBar>
    </ElevationScroll>
  );
};

export default NavBar;
