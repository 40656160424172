import { useState, useRef } from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { post } from "axios";

import Layout from "../layout";
import Loading from "../layout/Loading";

import en from "../../config/localization/en";

const initialFormData = Object.freeze({
  name: "",
  email: "",
  phone: "",
  password: "",
  referralCode: "",
});

const initialFormFiles = Object.freeze({
  "drivers-license": null,
  "nbi-plce": null,
  "gov-id": null,
  "vax-card": null,
  "or-cr": null,
  "motor-photo": null,
  authorization: null,
});

const RegistrationRider = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [formFiles, setFormFiles] = useState(initialFormFiles);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(false);
  const form2 = useRef(null);

  const handleOnChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
  };

  const handleOnChangeFile = (e) => {
    e.preventDefault();
    setFormFiles({ ...formFiles, ...{ [e.target.name]: e.target.files[0] } });
  };

  const successAlert = () => {
    return (
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        Thank you for your registration!
      </Alert>
    );
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    const newFormData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    for (const key in formData) {
      newFormData.append(key, formData[key]);
    }

    for (const key in formFiles) {
      newFormData.append(key, formFiles[key]);
    }

    newFormData.append("type", "driver");
    post("https://1app.ph/handler/handler.php", newFormData, config)
      .then((response) => {
        const { data } = response;
        if (data.result === "success") {
          setErrors(null);
          setFormData(initialFormData);
          setFormFiles(initialFormFiles);
          setSuccess(true);
          setTimeout(() => setSuccess(false), 10000);
          form2.current.reset();
        } else if (data.result === "validation_failed") {
          setErrors(data.errors);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        console.error(err);
      });
  };

  return (
    <Layout>
      <Typography
        variant="h4"
        component="h4"
        color="primary"
        sx={{ textAlign: "center", mt: 2 }}
      >
        {en.appName}
      </Typography>
      <Box>
        <Container>
          <Paper
            sx={{
              width: ["100%", "100%", 1 / 2],
              my: 2,
              px: 2,
              py: 4,
              mx: "auto",
            }}
          >
            <Loading open={submitting} />

            <form onSubmit={handleOnSubmit} ref={form2}>
              <Typography
                variant="h4"
                component="h4"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                {en.becomeRider}
              </Typography>
              {success ? successAlert() : null}
              <Typography variant="h6" component="h6" color="primary">
                {en.personalInfo}
              </Typography>
              <TextField
                id="inp-name"
                name="name"
                sx={{ width: 1, my: 2 }}
                label={en.name}
                required
                value={formData.name}
                onChange={handleOnChange}
                error={errors && errors.name ? true : false}
                helperText={
                  errors && errors.name
                    ? errors.name.replace("name", en.name)
                    : null
                }
              />
              <TextField
                id="inp-email"
                name="email"
                sx={{ width: 1, my: 2 }}
                label={en.emailAdd}
                required
                variant="outlined"
                type="email"
                value={formData.email}
                onChange={handleOnChange}
                error={errors && errors.email ? true : false}
                helperText={
                  errors && errors.email
                    ? errors.email.replace("email", en.emailAdd)
                    : null
                }
              />
              <TextField
                id="inp-phone"
                name="phone"
                maxLength={11}
                minLength={11}
                sx={{ width: 1, my: 2 }}
                label={en.phone}
                required
                variant="outlined"
                value={formData.phone}
                onChange={handleOnChange}
                error={errors && errors.phone ? true : false}
                helperText={
                  errors && errors.phone
                    ? errors.phone.replace("phone", en.phone)
                    : null
                }
              />
              <TextField
                id="inp-desired-password"
                name="password"
                label={en.password}
                variant="outlined"
                sx={{ width: 1, my: 2 }}
                type="password"
                required
                value={formData.password}
                onChange={handleOnChange}
                error={errors && errors.password ? true : false}
                helperText={
                  errors && errors.password
                    ? errors.password.replace("password", en.password)
                    : null
                }
              />
              <TextField
                id="inp-referralCode"
                name="referralCode"
                label={en.referalCode}
                variant="outlined"
                sx={{ width: 1, my: 2 }}
                value={formData.referralCode}
                onChange={handleOnChange}
              />
              <Typography variant="h6" component="h6" color="primary">
                {en.documents}
              </Typography>
              <TextField
                id="inp-drivers-license"
                name="drivers-license"
                label={en.driversLicense}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ width: 1, my: 2 }}
                type="file"
                required
                defaultValue={formFiles["drivers-license"]}
                onChange={handleOnChangeFile}
              />
              <TextField
                id="inp-nbi-pol"
                name="nbi-plce"
                label={en.nbiPol}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ width: 1, my: 2 }}
                type="file"
                defaultValue={formFiles["nbi-plce"]}
                onChange={handleOnChangeFile}
              />
              <TextField
                id="inp-gov-id"
                name="gov-id"
                label={en.govId}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ width: 1, my: 2 }}
                type="file"
                defaultValue={formFiles["gov-id"]}
                onChange={handleOnChangeFile}
              />
              <TextField
                id="inp-covid-vaccination-card"
                name="vax-card"
                label={en.covidVax}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ width: 1, my: 2 }}
                type="file"
                defaultValue={formFiles["vax-card"]}
                onChange={handleOnChangeFile}
              />
              <TextField
                id="inp-or-cr"
                name="or-cr"
                label={en.orCr}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ width: 1, my: 2 }}
                type="file"
                required
                defaultValue={formFiles["or-cr"]}
                onChange={handleOnChangeFile}
              />
              <TextField
                id="inp-motor-photo"
                name="motor-photo"
                label={en.motorPhoto}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ width: 1, my: 2 }}
                type="file"
                required
                defaultValue={formFiles["motor-photo"]}
                onChange={handleOnChangeFile}
              />
              <TextField
                id="inp-loa"
                name="authorization"
                label={en.loa}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ width: 1, my: 2 }}
                type="file"
                defaultValue={formFiles.authorization}
                onChange={handleOnChangeFile}
              />

              <Button
                variant="contained"
                size="medium"
                fullWidth
                type="submit"
                disabled={submitting}
              >
                {submitting ? en.sending : en.submit}
              </Button>
            </form>
          </Paper>
        </Container>
      </Box>
    </Layout>
  );
};

export default RegistrationRider;
