const typography = {
  fontFamily: '"Oswald", sans-serif',
  h1: {
    fontFamily: '"Oswald", sans-serif',
  },
  h2: {
    fontFamily: '"Oswald", sans-serif',
  },
  h3: {
    fontFamily: '"Oswald", sans-serif',
  },
  h4: {
    fontFamily: '"Oswald", sans-serif',
  },
  h5: {
    fontFamily: '"Oswald", sans-serif',
  },
  h6: {
    fontFamily: '"Oswald", sans-serif',
  },
};

export default typography;
