import { createTheme } from "@mui/material/styles";
import typography from "./typography";
import palette from "./pallete";
import override from "./override";

const theme = createTheme({
  palette,
  typography,
  override,
});

export default theme;
