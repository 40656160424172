import { useState, useRef } from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { post } from "axios";

import Layout from "../layout";
import Loading from "../layout/Loading";

import en from "../../config/localization/en";

const vendorType = [
  "Courier",
  "Restaurant / Food Shop",
  "Grocery",
  "Pharmacy",
  "Home Service",
  "Laundry",
  "Palengke",
  "Hardware",
];

const initialFormData = Object.freeze({
  "business-name": "",
  "vendor-store-street1": "",
  "vendor-store-street2": "",
  "vendor-store-city": "",
  "vendor-email": "",
  "vendor-type": "Courier",
  "vendor-phone": "",
  "vendor-p-name": "",
  "vendor-p-email": "",
  "vendor-p-phone": "",
  "vendor-password": "",
});

const initialFormFiles = Object.freeze({
  "business-permit": null,
  "vendor-dti-sec": null,
  "phto-establishment": null,
});

const RegistrationMerchant = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [formFiles, setFormFiles] = useState(initialFormFiles);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(false);
  const form = useRef(null);

  const handleOnChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
  };

  const handleOnChangeFile = (e) => {
    e.preventDefault();
    setFormFiles({ ...formFiles, ...{ [e.target.name]: e.target.files[0] } });
  };

  const successAlert = () => {
    return (
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        Thank you for your registration!
      </Alert>
    );
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    const newFormData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    for (const key in formData) {
      newFormData.append(key, formData[key]);
    }
    for (const key in formFiles) {
      newFormData.append(key, formFiles[key]);
    }
    newFormData.append("type", "vendor");

    post("https://1app.ph/handler/handlerMerchant.php", newFormData, config)
      .then((response) => {
        const { data } = response;
        if (data.result === "success") {
          setErrors(null);
          setFormData(initialFormData);
          setFormFiles(initialFormFiles);
          setSuccess(true);
          setTimeout(() => setSuccess(false), 10000);
          form.current.reset();
        } else if (data.result === "validation_failed") {
          setErrors(data.errors);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        console.error(err);
      });
  };

  return (
    <Layout>
      <Typography
        variant="h4"
        component="h4"
        color="primary"
        sx={{ textAlign: "center", mt: 2 }}
      >
        {en.appName}
      </Typography>
      <Box>
        <Container>
          <Paper
            sx={{
              width: ["100%", "100%", 1 / 2],
              my: 2,
              px: 2,
              py: 4,
              mx: "auto",
            }}
          >
            <Loading open={submitting} />
            <form onSubmit={handleOnSubmit} ref={form}>
              <Typography
                variant="h4"
                component="h4"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                {en.becomeMerchant}
              </Typography>
              {success ? successAlert() : null}
              <Typography
                sx={{ mt: 2 }}
                variant="h6"
                component="h6"
                color="primary"
              >
                {en.businessInfo}
              </Typography>
              <TextField
                id="inp-business-name"
                name="business-name"
                sx={{ width: 1, my: 2 }}
                label={en.businessName}
                variant="outlined"
                value={formData["business-name"]}
                onChange={handleOnChange}
                error={errors && errors["business-name"] ? true : false}
                helperText={
                  errors && errors["business-name"]
                    ? errors["business-name"].replace(
                        "business-name",
                        en.businessName
                      )
                    : null
                }
                required
              />
              <TextField
                id="inp-vendor-type"
                name="vendor-type"
                label={en.vendorType}
                variant="outlined"
                select
                sx={{ width: 1, my: 2 }}
                value={formData["vendor-type"]}
                onChange={handleOnChange}
                error={errors && errors["vendor-type"] ? true : false}
                helperText={
                  errors && errors["vendor-type"]
                    ? errors["vendor-type"].replace(
                        "vendor-type",
                        en.vendorType
                      )
                    : null
                }
                required
              >
                {vendorType.map((item, i) => (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="inp-business-email"
                name="vendor-email"
                sx={{ width: 1, my: 2 }}
                label={en.businessEmail}
                variant="outlined"
                value={formData["vendor-email"]}
                onChange={handleOnChange}
                error={errors && errors["vendor-email"] ? true : false}
                helperText={
                  errors && errors["vendor-email"]
                    ? errors["vendor-email"].replace(
                        "vendor-email",
                        en.businessEmail
                      )
                    : null
                }
                required
              />
              <TextField
                id="inp-phone"
                name="vendor-phone"
                maxLength={11}
                sx={{ width: 1, my: 2 }}
                label={en.phone}
                variant="outlined"
                value={formData["vendor-phone"]}
                onChange={handleOnChange}
                error={errors && errors["vendor-phone"] ? true : false}
                helperText={
                  errors && errors["vendor-phone"]
                    ? errors["vendor-phone"].replace("vendor-phone", en.phone)
                    : null
                }
              />
              <Typography
                sx={{ mt: 2 }}
                variant="h6"
                component="h6"
                color="primary"
              >
                {en.storeAddress}
              </Typography>
              <TextField
                id="inp-vendor-store-street1"
                name="vendor-store-street1"
                sx={{ width: 1, my: 2 }}
                label={en.storeStreet1}
                variant="outlined"
                value={formData["vendor-store-street1"]}
                onChange={handleOnChange}
                error={errors && errors["vendor-store-street1"] ? true : false}
                helperText={
                  errors && errors["vendor-store-street1"]
                    ? errors["vendor-store-street1"].replace(
                        "vendor-store-street1",
                        en.storeStreet1
                      )
                    : null
                }
                required
              />
              <TextField
                id="inp-vendor-store-street2"
                name="vendor-store-street2"
                sx={{ width: 1, my: 2 }}
                label={en.storeStreet2}
                variant="outlined"
                value={formData["vendor-store-street2"]}
                onChange={handleOnChange}
                error={errors && errors["vendor-store-street2"] ? true : false}
                helperText={
                  errors && errors["vendor-store-street2"]
                    ? errors["vendor-store-street2"].replace(
                        "vendor-store-street2",
                        en.storeStreet2
                      )
                    : null
                }
                required
              />
              <TextField
                id="inp-store-city"
                name="vendor-store-city"
                sx={{ width: 1, my: 2 }}
                label={en.storeCity}
                variant="outlined"
                value={formData["vendor-store-city"]}
                onChange={handleOnChange}
                error={errors && errors["vendor-store-city"] ? true : false}
                helperText={
                  errors && errors["vendor-store-city"]
                    ? errors["vendor-store-city"].replace(
                        "vendor-store-city",
                        en.storeCity
                      )
                    : null
                }
                required
              />
              <Typography variant="h6" component="h6" color="primary">
                {en.documents}
              </Typography>
              <TextField
                id="inp-business-permit"
                name="business-permit"
                label={en.businessPermit}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ width: 1, my: 2 }}
                type="file"
                defaultValue={formFiles["business-permit"]}
                onChange={handleOnChangeFile}
                required
              />
              <TextField
                id="inp-dti-sec"
                name="vendor-dti-sec"
                label={en.dtiSec}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ width: 1, my: 2 }}
                type="file"
                defaultValue={formFiles["vendor-dti-sec"]}
                onChange={handleOnChangeFile}
                required
              />
              <TextField
                id="inp-photo-establishment"
                name="phto-establishment"
                label={en.photoEstab}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ width: 1, my: 2 }}
                type="file"
                defaultValue={formFiles["phto-establishment"]}
                onChange={handleOnChangeFile}
                required
              />
              <Typography variant="h6" component="h6" color="primary">
                {en.personalInfo}
              </Typography>
              <TextField
                id="inp-name"
                name="vendor-p-name"
                sx={{ width: 1, my: 2 }}
                label={en.name}
                value={formData["vendor-p-name"]}
                onChange={handleOnChange}
                error={errors && errors["vendor-p-name"] ? true : false}
                helperText={
                  errors && errors["vendor-p-name"]
                    ? errors["vendor-p-name"].replace("vendor-p-name", en.name)
                    : null
                }
                required
              />
              <TextField
                id="inp-email"
                name="vendor-p-email"
                sx={{ width: 1, my: 2 }}
                label={en.emailAdd}
                variant="outlined"
                type="email"
                value={formData["vendor-p-email"]}
                onChange={handleOnChange}
                error={errors && errors["vendor-p-email"] ? true : false}
                helperText={
                  errors && errors["vendor-p-email"]
                    ? errors["vendor-p-email"].replace(
                        "vendor-p-email",
                        en.emailAdd
                      )
                    : null
                }
                required
              />
              <TextField
                id="inp-phone"
                name="vendor-p-phone"
                sx={{ width: 1, my: 2 }}
                label={en.phone}
                variant="outlined"
                value={formData["vendor-p-phone"]}
                onChange={handleOnChange}
                error={errors && errors["vendor-p-phone"] ? true : false}
                helperText={
                  errors && errors["vendor-p-phone"]
                    ? errors["vendor-p-phone"].replace(
                        "vendor-p-phone",
                        en.phone
                      )
                    : null
                }
                required
              />
              <TextField
                id="inp-desired-password"
                name="vendor-password"
                label={en.password}
                variant="outlined"
                sx={{ width: 1, my: 2 }}
                type="password"
                value={formData["vendor-password"]}
                onChange={handleOnChange}
                error={errors && errors["vendor-password"] ? true : false}
                helperText={
                  errors && errors["vendor-password"]
                    ? errors["vendor-password"].replace(
                        "vendor-password",
                        en.password
                      )
                    : null
                }
                required
              />
              <Button
                variant="contained"
                size="medium"
                fullWidth
                disabled={submitting}
                type="submit"
              >
                {submitting ? en.sending : en.submit}
              </Button>
            </form>
          </Paper>
        </Container>
      </Box>
    </Layout>
  );
};

export default RegistrationMerchant;
