import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import MLink from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { elements } from '../../router';

const Sidebar = ({ open, setOpen }) => {
  const toggleDrawer = (isOpen) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(isOpen);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {elements
          .filter((ele) => ele.mainNav === true)
          .map((item, index) =>
            item.path ? (
              <ListItemButton component={Link} to={item.path} key={index}>
                <ListItemText primary={item.name.toUpperCase()} />
              </ListItemButton>
            ) : (
              <ListItemButton
                component={MLink}
                href={item.link}
                target="_blank"
                rel="noreferrer"
                key={index}
              >
                <ListItemText primary={item.name.toUpperCase()} />
              </ListItemButton>
            )
          )}
      </List>
      <Divider />
      <List>
        {elements
          .filter((ele) => ele.mainNav === false && ele.path !== '*')
          .map((item, index) => (
            <ListItemButton component={Link} to={item.path} key={index}>
              <ListItemText primary={item.name.toUpperCase()} />
            </ListItemButton>
          ))}
      </List>
    </Box>
  );

  return (
    <div>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
};

export default Sidebar;
