import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';

import en from '../../config/localization/en';

const Footer = () => {
  return (
    <Box
      sx={{
        width: 1,
        textAlign: 'center',
        minHeight: '200px',
        pt: 5,
        backgroundColor: '#ccc'
      }}
    >
      <Container>
        <Button href="https://bit.ly/1appFB" target="_blank" rel="noreferrer">
          <FacebookIcon />
        </Button>
        <Button
          href="https://bit.ly/1appInstagram"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon />
        </Button>
        <Button
          href="https://bit.ly/1AppPhilippinesYouTube"
          target="_blank"
          rel="noreferrer"
        >
          <YouTubeIcon />
        </Button>

        <Typography variant="subtitle1" component="div" my={2}>
          Powered by 1app
        </Typography>

        <Typography variant="subtitle1" component="div" my={2}>
          <Link
            href="https://1app.ph/privacy-policy.html"
            target="_blank"
            underline="hover"
          >
            {en.privacyPolicy}
          </Link>{' '}
          |{' '}
          <Link
            href="https://1app.ph/terms-and-conditions.html"
            target="_blank"
            underline="hover"
          >
            {en.termsAndConditions}
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
