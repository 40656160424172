import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

export const NotFound = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        verticalAlign: 'center',
        height: '100vh',
        margin: 'auto 0'
      }}
    >
      <Typography variant="h2" gutterBottom>
        Page Not Found
      </Typography>
      <p>The page you are looking for does not exist.</p>
      <Link to="/">Go to Home</Link>
    </Box>
  );
};
