import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ImgBackground from "../../assets/images/background.jpg";

import { InvitationItem } from "./Home";

import Layout from "../layout";

import en from "../../config/localization/en";
import constants from "../../config/constants";
const { invitation } = constants;

const Registration = () => {
  return (
    <Layout>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            background: "#7f0102",
          },
          [theme.breakpoints.up("md")]: {
            background: `url(${ImgBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          },
        })}
      >
        <Typography
          variant="h3"
          component="h5"
          color="white"
          py={3}
          sx={{ textAlign: "center" }}
        >
          {`${en.appName} Registration`}
        </Typography>
        <Container sx={{ py: 5 }}>
          <Grid container rowGap={1}>
            {invitation.map((item, i) => (
              <InvitationItem key={i} item={item} />
            ))}
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default Registration;
