import { useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Box } from "@mui/material";

import Slide1 from "../assets/images/slide1.png";
import Slide2 from "../assets/images/slide2.png";
import Slide3 from "../assets/images/slide3.png";
import Slide4 from "../assets/images/slide4.png";

const Slider = () => {
  const [index, setIndex] = useState(0);

  const items = [
    {
      src: Slide1,
      alt: "No Time or Hate Traffic?",
    },
    {
      src: Slide2,
      alt: "DELI-BILIS",
    },
    {
      src: Slide3,
      alt: "HOME SERVICE",
    },
    {
      src: Slide4,
      alt: "HOME SERVICE",
    },
  ];

  return (
    <div>
      <Carousel
        autoPlay={false}
        navButtonsAlwaysVisible
        animation="slide"
        sx={(theme) => ({
          height: [
            "205px",
            "340px",
            "505px",
            "675px",
            `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
          ],
        })}
        index={index}
        indicators={false}
      >
        {items.map((item, i) => (
          <Slide key={i} item={item} />
        ))}
      </Carousel>
    </div>
  );
};

const Slide = (props) => {
  const { item } = props;

  return (
    <Box sx={{ borderRadius: 0, padding: 0 }}>
      <img src={item.src} alt={item.alt} style={{ width: "100vw" }} />
    </Box>
  );
};

export default Slider;
